import axios from 'axios'
import Routing from '@js/Routing'
import { documentSectionTypeToShortName } from '@js/model/document'
import type { DialogConfig } from '@js/composable/useSectionEditor'
import type { AnyDocumentWidget, Document, DocumentSection } from '@js/model/document'
import type { HydraCollectionResponse } from '@js/types'
import type { GroupPermission, UserPermission } from '@js/model/permission'
import type { MessageBag } from '@js/stores/notifications'
import type { Attachment } from '@js/model/attachment'
import type { Country } from '@js/model/country'
import type { Task, TaskExtra, TaskShortName } from '@js/model/task'

export type DocumentEditDataResponse = {
  newSectionPath: string
  attachments: Array<Attachment>
  sections: Array<DocumentSection>
  documentName: string
  sectionsHtml: string
  routes: { edit: string }
  userCanEditContent: boolean
  userCanEditConfiguration: boolean
}

export type DocumentSectionPlacement = 'before' | 'after' | 'subsection-of'

export type NewSectionOptions = {
  placement: DocumentSectionPlacement
  referenceSectionId: DocumentSection['id']
}

export function fetchDocumentByTask(shortName: TaskShortName, id: TaskExtra['taskTypeId']) {
  return axios.get<DocumentEditDataResponse>(
    Routing.generate('u2_tasktype_editdocument_data', {
      shortName,
      id,
    })
  )
}

export function fetchDocumentRenderedSections(
  shortName: TaskShortName,
  id: TaskExtra['taskTypeId']
) {
  return axios.get<Array<{ id: DocumentSection['id']; content: string }>>(
    Routing.generate('u2_tasktype_renderdocumentsections', {
      shortName,
      id,
    })
  )
}

export function addDocumentInitialSection(initialSectionData: {
  shortName: TaskShortName
  documentId: TaskExtra['taskTypeId']
  name: DocumentSection['name']
  content?: DocumentSection['content']
}) {
  const sectionFormData = new FormData()
  sectionFormData.append(
    'document_section_initial_add_form[sectionToManipulate][name]',
    initialSectionData.name
  )
  if (initialSectionData.content) {
    sectionFormData.append(
      'document_section_initial_add_form[sectionToManipulate][content]',
      initialSectionData.content
    )
  }

  return axios.post<{ success: boolean; messages: MessageBag }>(
    Routing.generate('u2_documentsection_newinitialsection', {
      shortName: initialSectionData.shortName,
      documentId: initialSectionData.documentId,
    }),
    sectionFormData
  )
}

export function addDocumentSection(
  section: Partial<DocumentSection> & Pick<DocumentSection, '@type' | 'name'>,
  options: NewSectionOptions
) {
  const { placement, referenceSectionId } = options
  const sectionFormData = new FormData()
  sectionFormData.append('document_section_add_form[sectionToManipulate][name]', section.name)
  return axios.post<{ success: boolean; messages: MessageBag }>(
    Routing.generate('u2_documentsection_create', {
      placement,
      referenceSectionId,
      shortName: documentSectionTypeToShortName[section['@type']],
    }),
    sectionFormData
  )
}

export function fetchDocumentSectionInformation(section: DocumentSection) {
  return axios.get<DocumentSection>(
    Routing.generate('u2_documentsection_informationjson', {
      sectionId: section.id,
      shortName: documentSectionTypeToShortName[section['@type']],
    })
  )
}

export function excludeDocumentSection(section: DocumentSection) {
  return axios.get(
    Routing.generate('u2_documentsection_exclude', {
      sectionId: section.id,
      shortName: documentSectionTypeToShortName[section['@type']],
    })
  )
}

export function includeDocumentSection(section: DocumentSection) {
  return axios.get(
    Routing.generate('u2_documentsection_include', {
      sectionId: section.id,
      shortName: documentSectionTypeToShortName[section['@type']],
    })
  )
}

export function allowDocumentSectionEdit(section: DocumentSection) {
  return axios.get(
    Routing.generate('u2_documentsection_allowedit', {
      sectionId: section.id,
      shortName: documentSectionTypeToShortName[section['@type']],
    })
  )
}

export function preventDocumentSectionEdit(section: DocumentSection) {
  return axios.get(
    Routing.generate('u2_documentsection_preventedit', {
      sectionId: section.id,
      shortName: documentSectionTypeToShortName[section['@type']],
    })
  )
}

export function requireDocumentSection(section: DocumentSection) {
  return axios.get(
    Routing.generate('u2_documentsection_require', {
      sectionId: section.id,
      shortName: documentSectionTypeToShortName[section['@type']],
    })
  )
}

export function unrequireDocumentSection(section: DocumentSection) {
  return axios.get(
    Routing.generate('u2_documentsection_unrequire', {
      sectionId: section.id,
      shortName: documentSectionTypeToShortName[section['@type']],
    })
  )
}

export function fetchDocumentBmfCountries(taskTypeId: TaskExtra['taskTypeId']) {
  return axios.get<{ countries: Array<Country> }>(
    Routing.generate('u2_xbrl_bmf_downloadlistjson', { id: taskTypeId })
  )
}
export function downloadDocumentOecdReport(taskTypeId: TaskExtra['taskTypeId']) {
  return axios.post<{ xml: string; name: string }>(
    `/api/country-by-country-reports/${taskTypeId}/xbrl-download`,
    {}
  )
}

export function downloadDocumentBmfReport(
  taskTypeId: TaskExtra['taskTypeId'],
  countryId: Country['id']
) {
  return axios.post<{ xml: string; name: string }>(
    `/api/country-by-country-reports/${taskTypeId}/country/${countryId}/xbrl-download`,
    {}
  )
}

export function downloadDocumentAttachments(params: {
  shortName: TaskShortName
  documentId: Document['id']
}) {
  return axios.post(
    Routing.generate('u2_attachmentsappendix_downloadarchive', params),
    {},
    { responseType: 'blob' }
  )
}

export function getDocumentTaskPermissionsPath(documentTask: Task) {
  switch (documentTask['u2:extra'].shortName) {
    case 'tpm-master-file':
      return `/api/master-files/${documentTask['u2:extra'].taskTypeId}`
    case 'tpm-local-file':
      return `/api/local-files/${documentTask['u2:extra'].taskTypeId}`
    case 'tpm-country-by-country-report':
      return `/api/country-by-country-reports/${documentTask['u2:extra'].taskTypeId}`
    default:
      throw new Error(
        'Resource with shortname ' +
          documentTask['u2:extra'].shortName +
          ' is not a permissionable entity.'
      )
  }
}

export function fetchDocumentUserPermissions(documentTask: Task) {
  return axios.get<HydraCollectionResponse<UserPermission>>(
    `${getDocumentTaskPermissionsPath(documentTask)}/user-permissions`
  )
}

export function fetchDocumentGroupPermissions(documentTask: Task) {
  return axios.get<HydraCollectionResponse<GroupPermission>>(
    `${getDocumentTaskPermissionsPath(documentTask)}/group-permissions`
  )
}

export function updateDocumentUserPermissions(
  documentTask: Task,
  userPermissions: Array<Omit<UserPermission, '@id' | 'id'>>
) {
  return axios.patch<HydraCollectionResponse<UserPermission>>(
    `${getDocumentTaskPermissionsPath(documentTask)}/user-permissions`,
    { userPermissions }
  )
}

export function updateDocumentGroupPermissions(
  documentTask: Task,
  groupPermissions: Array<Omit<GroupPermission, '@id' | 'id'>>
) {
  return axios.patch<HydraCollectionResponse<GroupPermission>>(
    `${getDocumentTaskPermissionsPath(documentTask)}/group-permissions`,
    { groupPermissions }
  )
}

export function fetchDocumentWidgetEditorConfiguration(
  section: DocumentSection,
  widget: AnyDocumentWidget
) {
  return axios.get<DialogConfig>(Routing.generate('u2_documentwidget_editorconfiguration'), {
    params: {
      sectionContext: JSON.stringify({
        section_id: section.id,
        section_short_name:
          documentSectionTypeToShortName[
            section['@type'] as keyof typeof documentSectionTypeToShortName
          ],
      }),
      widget: JSON.stringify(widget),
    },
  })
}

export function deleteDocumentSection(payload: {
  id: DocumentSection['id']
  type: DocumentSection['@type']
  shouldDeleteSubsections: boolean
}) {
  const formData = new FormData()

  if (payload.shouldDeleteSubsections) {
    formData.append(
      'document_section_remove_form[removeSubsections]',
      payload.shouldDeleteSubsections.toString()
    )
  } else {
    // This is needed because symfony forms will not accept empty forms as submitted
    formData.append('document_section_remove_form[ensureFormIsSubmitted]', '')
  }

  return axios.post(
    Routing.generate('u2_documentsection_remove', {
      sectionId: payload.id,
      shortName: documentSectionTypeToShortName[payload.type],
    }),
    formData
  )
}

export function moveDocumentSection(payload: {
  id: DocumentSection['id']
  type: DocumentSection['@type']
  placement: DocumentSectionPlacement
  referenceSection: DocumentSection['id']
}) {
  const formData = new FormData()

  formData.append('document_section_move_form[placement]', payload.placement)
  formData.append(
    'document_section_move_form[referenceSection]',
    payload.referenceSection.toString()
  )

  return axios.post(
    Routing.generate('u2_documentsection_move', {
      sectionId: payload.id,
      shortName: documentSectionTypeToShortName[payload.type],
    }),
    formData
  )
}

export function updateDocumentSection(payload: {
  id: DocumentSection['id']
  type: DocumentSection['@type']
  name: string
  content: string
}) {
  const formData = new FormData()

  formData.append('document_section_form[name]', payload.name)
  formData.append('document_section_form[content]', payload.content)

  return axios.post(
    Routing.generate('u2_documentsection_update', {
      sectionId: payload.id,
      shortName: documentSectionTypeToShortName[payload.type],
    }),
    formData
  )
}
