import { createQueryKeys } from '@lukemorales/query-key-factory'
import invariant from 'tiny-invariant'
import { toValue } from 'vue'
import * as UnitApi from '@js/api/unitApi'
import type { MaybeRefOrGetter } from 'vue'
import type { UnitCollectionQuery } from '@js/api/unitApi'
import type { Unit } from '@js/model/unit'

export const units = createQueryKeys('units', {
  all: {
    queryKey: null,
    queryFn: () => UnitApi.fetchAllUnits().then((response) => response.data),
  },
  list: (filters?: MaybeRefOrGetter<UnitCollectionQuery>) => ({
    queryKey: [filters],
    queryFn: () =>
      UnitApi.fetchUnitsByQuery({ ...toValue(filters) }).then((response) => response.data),
    contextQueries: {
      basic: {
        queryKey: null,
        queryFn: () =>
          UnitApi.fetchBasicUnitsByQuery({ ...toValue(filters) }).then((response) => response.data),
      },
    },
  }),
  single: (id: MaybeRefOrGetter<Unit['id'] | undefined>) => {
    return {
      queryKey: [id],
      queryFn: () => {
        const value = toValue(id)
        invariant(value)
        return UnitApi.fetchUnitById(value).then((response) => response.data)
      },
      contextQueries: {
        basic: {
          queryKey: null,
          queryFn: () => {
            const value = toValue(id)
            invariant(value)
            return UnitApi.fetchBasicUnitById(value).then((response) => response.data)
          },
        },
        directUsers: {
          queryKey: null,
          queryFn: () => {
            const value = toValue(id)
            invariant(value)
            return UnitApi.fetchUnitAssignedUsers(value).then((response) => response.data)
          },
        },
        inheritedUsers: {
          queryKey: null,
          queryFn: () => {
            const value = toValue(id)
            invariant(value)
            return UnitApi.fetchUnitInheritedAssignedUsers(value).then((response) => response.data)
          },
        },
        userGroups: {
          queryKey: null,
          queryFn: () => {
            const value = toValue(id)
            invariant(value)
            return UnitApi.fetchUnitAssignedUserGroups(value).then((response) => response.data)
          },
        },
        auditLogInfinite: {
          queryKey: null,
        },
      },
    }
  },
})
