import axios from 'axios'
import type { SortingDirection } from '@js/utilities/getNextSortingDirection'
import type { FileType } from '@js/model/fileType'
import type { ApiPaginationQueryParams, HydraCollectionResponse } from '@js/types'

const basePath = '/api/configuration/file-types'

type FileTypeCollectionQuery = ApiPaginationQueryParams & {
  'sort[name]'?: SortingDirection
  'sort[enabled]'?: SortingDirection
  search?: string | Array<string>
}

export function fetchFileTypeById(id: FileType['id']) {
  return axios.get<FileType>(`${basePath}/${id}`)
}
export function fetchAllFileTypes() {
  return axios.get<HydraCollectionResponse<FileType>>(basePath, {
    params: { sort: { name: 'ASC' }, pagination: false },
  })
}
export function saveFileType(fileType: Partial<FileType>) {
  if (fileType.id === undefined) {
    return axios.post<FileType>(basePath, fileType)
  }
  return axios.patch<FileType>(`${basePath}/${fileType.id}`, fileType)
}

export function fetchFileTypeByQuery(query: FileTypeCollectionQuery) {
  return axios.get<HydraCollectionResponse<FileType>>(basePath, {
    params: { ...query },
  })
}

export function deleteFileType(fileType: FileType) {
  return axios.delete(`${fileType['@id']}`)
}
