import axios from 'axios'
import type { TableDomData } from '@js/api/taskTypeApi'
import type { Dashboard, FilterStatisticsWidget } from '@js/model/dashboard'
import type { ApiPaginationQueryParams, HydraCollectionResponse } from '@js/types'
import type { User } from '@js/model/user'
import type { UserGroup } from '@js/model/userGroup'

const basePath = '/api/dashboards'
export function fetchAllDashboards() {
  return axios.get<HydraCollectionResponse<Dashboard>>(basePath, {
    params: { pagination: false },
  })
}
export function fetchDashboardById(id: Dashboard['id']) {
  return axios.get<Dashboard>(`${basePath}/${id}`)
}

export function fetchDashboardAssignedUserGroups(id: Dashboard['id']) {
  return axios.get<HydraCollectionResponse<UserGroup>>(`${basePath}/${id}/groups`, {
    params: { pagination: false },
  })
}

export function updateDashboardAssignedUserGroups(
  id: Dashboard['id'],
  updatedGroups: Array<NonNullable<UserGroup['@id']>>
) {
  return axios.patch<HydraCollectionResponse<UserGroup>>(`${basePath}/${id}/groups`, {
    groups: updatedGroups,
  })
}

export function fetchDashboardAssignedUsers(id: Dashboard['id']) {
  return axios.get<HydraCollectionResponse<User>>(`${basePath}/${id}/direct-users`, {
    params: { pagination: false },
  })
}

export function updateDashboardAssignedUsers(
  id: Dashboard['id'],
  updatedUsers: Array<NonNullable<User['@id']>>
) {
  return axios.patch<HydraCollectionResponse<User>>(`${basePath}/${id}/direct-users`, {
    directUsers: updatedUsers,
  })
}

export function fetchDashboardInheritedAssignedUsers(id: Dashboard['id']) {
  return axios.get<HydraCollectionResponse<User>>(`${basePath}/${id}/inherited-users`, {
    params: { pagination: false },
  })
}

export function createDashboard(payload: Partial<Dashboard>) {
  return axios.post<Dashboard>(basePath, payload)
}

export function updateDashboard(payload: Partial<Dashboard>) {
  return axios.patch<Dashboard>(`${basePath}/${payload.id}`, payload)
}

export const deleteDashboardById = function (id: Dashboard['id']) {
  return axios.delete(`${basePath}/${id}`)
}

export function fetchDashboardsByQuery(query: ApiPaginationQueryParams) {
  return axios.get<HydraCollectionResponse<Dashboard>>(basePath, {
    params: { ...query },
  })
}

type FilterResultsWidgetData = {
  taskType: string
  listPath: string
  title: string
  data: TableDomData & {
    totalItems: number
  }
}

export type SavedFilterConfiguration = {
  columns: Array<string>
  records_per_page: number
  sort: string | undefined
  filter_id?: number
}
export function fetchFilterResultsWidget(params: {
  page: number
  savedFilterConfiguration: SavedFilterConfiguration
}) {
  return axios.get<FilterResultsWidgetData>('/api/dashboard/widget/filter-results', {
    params: {
      page: params.page,
      widget: JSON.stringify({
        name: 'filter-results',
        parameters: params.savedFilterConfiguration,
      }),
    },
  })
}

export function fetchFilterStatisticsWidget(
  statisticsConfiguration: FilterStatisticsWidget['parameters']
) {
  return axios.get<{
    data: Array<{
      label: string
      result: number
      color: string
      listPath: string
      savedFilterId: number
    }>
  }>('/api/dashboards/widgets/filter-statistics', {
    params: {
      widget: JSON.stringify({ name: 'filter-statistics', parameters: statisticsConfiguration }),
    },
  })
}
