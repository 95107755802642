import axios from 'axios'
import type { ApiPaginationQueryParams, HydraCollectionResponse } from '@js/types'
import type { SortingDirection } from '@js/utilities/getNextSortingDirection'
import type { Branch } from '@js/model/branch'

export const basePath = '/api/configuration/branches'

type BranchCollectionQuery = ApiPaginationQueryParams & {
  'sort[name]'?: SortingDirection
  'sort[enabled]'?: SortingDirection
  search?: string | Array<string>
}
export function fetchBranchByQuery(query?: BranchCollectionQuery) {
  return axios.get<HydraCollectionResponse<Branch>>(basePath, {
    params: { ...query },
  })
}

export function createBranch(branch: Partial<Branch> = {}) {
  return axios.post<Branch>(basePath, branch)
}

export function updateBranch(branch: Branch) {
  return axios.patch<Branch>(`${basePath}/${branch.id}`, branch)
}

export function deleteBranch(branch: Branch) {
  return axios.delete(`${branch['@id']}`)
}

export function fetchBranchById(id: Branch['id']) {
  return axios.get<Branch>(`${basePath}/${id}`)
}

export function deleteBranchById(id: Branch['id']) {
  return axios.delete(`${basePath}/${id}`)
}
