import axios from 'axios'
import type { SystemSettings } from '@js/model/system_setting'

const basePath = '/api/system-settings'

export function fetchSystemSettings() {
  return axios.get<SystemSettings>(basePath)
}

export function updateSystemSettings(systemSettings: Omit<SystemSettings, '@id' | '@type'>) {
  return axios.patch<SystemSettings>(basePath, {
    ...systemSettings,
  })
}

export const systemSettingApi = {
  basePath,
  fetchSystemSettings,
  updateSystemSettings,
}
