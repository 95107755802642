import axios from 'axios'
import type { HydraCollectionResponse } from '@js/types'
import type { Import, ImportData, ImportRequest, ImportType } from '@js/model/import'
import type { SortingDirection } from '@js/utilities/getNextSortingDirection'

export const importApiBasePath = '/api/imports'
const importDataBasePath = '/api/import-data'

export function fetchImportByIri(iri: Import['@id']) {
  return axios.get<Import>(iri)
}

export function fetchImportById(id: Import['id']) {
  return fetchImportByIri(`${importApiBasePath}/${id}`)
}

export function fetchImportsByQuery(query?: {
  page?: number
  itemsPerPage?: number
  pagination?: boolean
  'sort[name]'?: SortingDirection
  'bindings.bindingId'?: string | Array<string>
  search?: string | Array<string>
}) {
  return axios.get<HydraCollectionResponse<Import>>(importApiBasePath, {
    params: query,
  })
}

export function startImport(importRequest: ImportRequest) {
  return axios.post<Import>('/api/imports', importRequest)
}

export function fetchImportTypes() {
  return axios.get<HydraCollectionResponse<ImportType>>('/api/import-types')
}

export function fetchImportTypeByShortName(id: ImportType['shortName']) {
  return axios.get<ImportType>(`/api/import-types/${id.replaceAll(/_/g, '-')}`)
}

export function fetchImportDataById(id: ImportData['id']) {
  return axios.get<ImportData>(`${importDataBasePath}/${id}`)
}

export const importApi = {
  basePath: importApiBasePath,
  fetchImportByIri,
  fetchImportById,
  fetchImportsByQuery,
  startImport,
  fetchImportTypes,
  fetchImportTypeByShortName,
  fetchImportDataById,
}
