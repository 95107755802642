import axios from 'axios'
import type { AxiosResponse } from 'axios'
import type {
  AnyBasicUnit,
  AnyUnit,
  LegalUnit,
  OrganisationalGroup,
  PermanentEstablishment,
  Unit,
} from '@js/model/unit'
import type { AuditLog } from '@js/model/auditLog'
import type { ApiPaginationQueryParams, HydraCollectionResponse } from '@js/types'
import type { User } from '@js/model/user'
import type { UserGroup } from '@js/model/userGroup'
import type { SortingDirection } from '@js/utilities/getNextSortingDirection'
import type { PartialDeep, SetRequired } from 'type-fest'

export const unitApiBasePath = '/api/units'
export const basicUnitApiBasePath = '/api/basic-units'

export type UnitCollectionQuery = ApiPaginationQueryParams & {
  type?: 'unit' | 'legalunit' | 'permanentestablishment' | 'organisationalgroup'
  'sort[auditor.name]'?: SortingDirection
  'sort[branch.name]'?: SortingDirection
  'sort[contactUser.username]'?: SortingDirection
  'sort[country.nameShort]'?: SortingDirection
  'sort[countryFounded.nameShort]'?: SortingDirection
  'sort[createdAt]'?: SortingDirection
  'sort[createdBy.username]'?: SortingDirection
  'sort[currency.iso4217code]'?: SortingDirection
  'sort[description]'?: SortingDirection
  'sort[id]'?: SortingDirection
  'sort[legalForm.name]'?: SortingDirection
  'sort[legalName]'?: SortingDirection
  'sort[name]'?: SortingDirection
  'sort[parentLegalUnit.name]'?: SortingDirection
  'sort[parentLegalUnitIncomeTax.name]'?: SortingDirection
  'sort[parentLegalUnitVat.name]'?: SortingDirection
  'sort[refId]'?: SortingDirection
  'sort[registerNumber]'?: SortingDirection
  'sort[registryPlace]'?: SortingDirection
  'sort[taxAdvisor.name]'?: SortingDirection
  'sort[updatedAt]'?: SortingDirection
  'sort[updatedBy.username]'?: SortingDirection
  'sort[validFrom]'?: SortingDirection
  'sort[validTo]'?: SortingDirection
  'sort[vatNumber]'?: SortingDirection
  'sort[verified]'?: SortingDirection
  search?: string | Array<string>
}

export function fetchUnitsByQuery(query?: UnitCollectionQuery) {
  return axios.get<HydraCollectionResponse<AnyUnit>>(unitApiBasePath, {
    params: { ...query },
  })
}

export function fetchBasicUnitsByQuery(query?: UnitCollectionQuery) {
  return axios.get<HydraCollectionResponse<AnyBasicUnit>>(basicUnitApiBasePath, {
    params: { ...query },
  })
}

export function fetchAllUnits() {
  return fetchBasicUnitsByQuery({ pagination: false, 'sort[name]': 'ASC' })
}
export function fetchUnitAssignedUserGroups(id: number) {
  return axios.get<HydraCollectionResponse<UserGroup>>(`${unitApiBasePath}/${id}/groups`, {
    params: { pagination: false },
  })
}

export function updateUnitAssignedUserGroups(
  id: number,
  updatedGroups: Array<NonNullable<UserGroup['@id']>>
) {
  return axios.patch<HydraCollectionResponse<UserGroup>>(`${unitApiBasePath}/${id}/groups`, {
    groups: updatedGroups,
  })
}

export function fetchUnitAssignedUsers(id: number) {
  return axios.get<HydraCollectionResponse<User>>(`${unitApiBasePath}/${id}/direct-users`, {
    params: { pagination: false },
  })
}

export function updateUnitAssignedUsers(id: number, updatedUsers: Array<NonNullable<User['@id']>>) {
  return axios.patch<HydraCollectionResponse<User>>(`${unitApiBasePath}/${id}/direct-users`, {
    directUsers: updatedUsers,
  })
}

export function fetchUnitInheritedAssignedUsers(id: number) {
  return axios.get<HydraCollectionResponse<User>>(`${unitApiBasePath}/${id}/inherited-users`, {
    params: { pagination: false },
  })
}

export function fetchUnitById(id: AnyUnit['id']) {
  return axios.get<AnyUnit>(`${unitApiBasePath}/${id}`)
}

export function fetchBasicUnitById(id: AnyBasicUnit['id']) {
  return axios.get<AnyBasicUnit>(`${basicUnitApiBasePath}/${id}`)
}

export function createUnit(
  unit: SetRequired<Partial<Unit>, 'refId' | 'name' | 'verified' | '@type'>
) {
  return axios.post<Unit>(unitApiBasePath, unit)
}

export function updateUnit(unit: Partial<Unit>) {
  return axios.patch<Unit>(`${unitApiBasePath}/${unit.id}`, unit)
}

export function downloadUnitXls(query: UnitCollectionQuery) {
  return axios.get('/api/units', {
    responseType: 'blob',
    params: {
      ...query,
      pagination: false,
    },
    headers: {
      Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    },
  })
}

export function deleteUnit(unit: AnyUnit) {
  return axios.delete(`${unit['@id']}`)
}

export function fetchUnitLogsByQuery(unitId: AnyUnit['id'], query?: ApiPaginationQueryParams) {
  return axios.get<HydraCollectionResponse<AuditLog>>(`${unitApiBasePath}/${unitId}/logs`, {
    params: { ...query },
  })
}

export function createLegalUnit(
  legalUnit: SetRequired<
    Partial<LegalUnit>,
    'refId' | 'name' | 'currency' | 'country' | 'verified' | 'legalName' | '@type'
  >
) {
  return axios.post<LegalUnit>('/api/legal-units', legalUnit)
}

export function updateLegalUnit(
  legalUnit: Omit<PartialDeep<LegalUnit>, '@id'> & { '@id': LegalUnit['@id'] }
) {
  return axios.patch<LegalUnit>(legalUnit['@id'], legalUnit)
}

export function createOrganisationalGroup(
  organisationalGroup: SetRequired<
    Partial<OrganisationalGroup>,
    'refId' | 'name' | 'verified' | '@type'
  >
) {
  return axios.post<OrganisationalGroup>('/api/organisational-groups', organisationalGroup)
}

export function updateOrganisationalGroup(
  organisationalGroup: Omit<Partial<OrganisationalGroup>, '@id'> & {
    '@id': OrganisationalGroup['@id']
  }
) {
  return axios.patch<OrganisationalGroup>(organisationalGroup['@id'], organisationalGroup)
}

export function createPermanentEstablishment(
  permanentEstablishment: SetRequired<
    Partial<PermanentEstablishment>,
    'refId' | 'name' | 'parentLegalUnit' | 'verified' | 'legalName' | '@type'
  >
) {
  return axios.post<PermanentEstablishment>('/api/permanent-establishments', permanentEstablishment)
}

export function updatePermanentEstablishment(
  permanentEstablishment: Omit<PartialDeep<PermanentEstablishment>, '@id'> & {
    '@id': PermanentEstablishment['@id']
  }
) {
  return axios.patch<PermanentEstablishment>(permanentEstablishment['@id'], permanentEstablishment)
}

export function createAnyUnit<TUnit extends AnyUnit>(unit: TUnit): Promise<AxiosResponse<TUnit>> {
  switch (unit['@type']) {
    case 'LegalUnit':
      return createLegalUnit(unit) as Promise<AxiosResponse<TUnit>>
    case 'PermanentEstablishment':
      return createPermanentEstablishment(unit) as Promise<AxiosResponse<TUnit>>
    case 'OrganisationalGroup':
      return createOrganisationalGroup(unit) as Promise<AxiosResponse<TUnit>>
    case 'Unit':
      return createUnit(unit) as Promise<AxiosResponse<TUnit>>
    default:
      throw new Error(`Unknown unit type: ${unit['@type']}`)
  }
}

export function updateAnyUnit<TUnit extends AnyUnit>(unit: TUnit): Promise<AxiosResponse<TUnit>> {
  switch (unit['@type']) {
    case 'LegalUnit':
      return updateLegalUnit(unit) as Promise<AxiosResponse<TUnit>>
    case 'PermanentEstablishment':
      return updatePermanentEstablishment(unit) as Promise<AxiosResponse<TUnit>>
    case 'OrganisationalGroup':
      return updateOrganisationalGroup(unit) as Promise<AxiosResponse<TUnit>>
    case 'Unit':
      return updateUnit(unit) as Promise<AxiosResponse<TUnit>>
    default:
      throw new Error(`Unknown unit type: ${unit['@type']}`)
  }
}
