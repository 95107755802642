import axios from 'axios'
import type { Datasheet, LayoutItem } from '@js/model/datasheet'
import type { DatasheetCollection } from '@js/model/datasheetCollection'
import type { GroupPermission, UserPermission } from '@js/model/permission'
import type { ApiPaginationQueryParams, HydraCollectionResponse, NewApiResource } from '@js/types'
import type { SortingDirection } from '@js/utilities/getNextSortingDirection'
import type { AxiosRequestConfig, AxiosResponse } from 'axios'
import type { SetRequired } from 'type-fest'

export const datasheetApiBasePath = '/api/layouts'

export type LayoutCollectionQuery = ApiPaginationQueryParams & {
  'sort[name]'?: SortingDirection
  'sort[group]'?: SortingDirection
  'fields.item.refId'?: LayoutItem['refId']
  'fields.item.id'?: LayoutItem['id']
  permission?: 1 | 4 | 8 | 128
  search?: string | Array<string>
}

type UpdatedLayout = SetRequired<Partial<Datasheet>, 'id'>
type NewLayout = Pick<Datasheet, 'name' | 'group' | 'public'>

export function fetchDatasheetsByQuery(query?: LayoutCollectionQuery) {
  return axios.get<HydraCollectionResponse<Datasheet>>(datasheetApiBasePath, {
    params: { ...query },
  })
}

export function fetchDatasheetCollectionsForDatasheet(id: Datasheet['id']) {
  return axios.get<HydraCollectionResponse<DatasheetCollection>>(
    `${datasheetApiBasePath}/${id}/layout-collections`
  )
}

export function downloadDatasheetTemplate(id: Datasheet['id']) {
  return axios.get<Blob>(datasheetApiBasePath + `/${id}/template`, { responseType: 'blob' })
}

export function deleteDatasheetTemplate(id: Datasheet['id']) {
  return axios.delete(datasheetApiBasePath + `/${id}/template`)
}

export function uploadDatasheetTemplate(
  id: Datasheet['id'],
  payload: File,
  config?: AxiosRequestConfig
) {
  const formData = new FormData()
  formData.append('template', payload)

  return axios.post(`${datasheetApiBasePath}/${id}/template`, formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
    ...config,
  })
}
const update = function (payload: UpdatedLayout) {
  return axios.patch<Datasheet>(`${datasheetApiBasePath}/${payload.id}`, payload)
}
const create = function (payload: NewLayout) {
  return axios.post<NewApiResource<Datasheet>, AxiosResponse<Datasheet>>(
    `${datasheetApiBasePath}`,
    payload
  )
}
export function fetchDatasheetUserPermissions(datasheet: Datasheet) {
  return axios.get<HydraCollectionResponse<UserPermission>>(`${datasheet['@id']}/user-permissions`)
}

export function fetchDatasheetGroupPermissions(datasheet: Datasheet) {
  return axios.get<HydraCollectionResponse<GroupPermission>>(
    `${datasheet['@id']}/group-permissions`
  )
}

export function updateDatasheetUserPermissions(
  datasheet: Datasheet,
  userPermissions: Array<Omit<UserPermission, '@id' | 'id'>>
) {
  return axios.patch<HydraCollectionResponse<UserPermission>>(
    `${datasheet['@id']}/user-permissions`,
    { userPermissions }
  )
}

export function updateDatasheetGroupPermissions(
  datasheet: Datasheet,
  groupPermissions: Array<Omit<GroupPermission, '@id' | 'id'>>
) {
  return axios.patch<HydraCollectionResponse<GroupPermission>>(
    `${datasheet['@id']}/group-permissions`,
    { groupPermissions }
  )
}

export function saveDatasheet(payload: UpdatedLayout | NewLayout) {
  return 'id' in payload ? update(payload) : create(payload)
}

export function deleteDatasheetById(id: Datasheet['id']) {
  return axios.delete(`${datasheetApiBasePath}/${id}`)
}

export function fetchAllDatasheets() {
  return fetchDatasheetsByQuery({ pagination: false })
}

export function fetchDatasheetById(id: Datasheet['id']) {
  return axios.get<Datasheet>(`${datasheetApiBasePath}/${id}`)
}
