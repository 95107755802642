<script setup lang="ts">
import { onKeyStroke, useMagicKeys, useStorage } from '@vueuse/core'
import { computed, ref, watch } from 'vue'
import uniqBy from 'lodash/uniqBy'
import { useRouter } from 'vue-router'
import { useGlobalSearch } from '@js/composable/useGlobalSearch'
import AppLoader from '@js/components/loader/AppLoader.vue'
import GlobalSearchItem from '@js/components/GlobalSearchItem.vue'
import SvgIcon from '@js/components/SvgIcon.vue'
import Translator from '@js/translator'
import { AnimatePresence, Motion } from 'motion-v'
import {
  ComboboxAnchor,
  ComboboxContent,
  ComboboxEmpty,
  ComboboxGroup,
  ComboboxInput,
  ComboboxLabel,
  ComboboxRoot,
  ComboboxViewport,
  DialogContent,
  DialogDescription,
  DialogOverlay,
  DialogPortal,
  DialogRoot,
  DialogTitle,
  VisuallyHidden,
} from 'reka-ui'
import type { SearchResult } from '@js/composable/useGlobalSearch'

const isShowing = ref(true)
watch(isShowing, (newValue) => {
  if (!newValue) {
    emit('close')
  }
})

const { results, query, loading } = useGlobalSearch()
const recentSearches = useStorage<Array<SearchResult>>('recentSearches', [], undefined, {
  flush: 'sync',
})
const router = useRouter()

const selectedOption = ref()

function addToRecent(item: SearchResult) {
  const maxRecentResults = 10
  recentSearches.value = uniqBy([item, ...recentSearches.value], 'url')
    // Purge old results
    .slice(0, maxRecentResults)
    .filter((item) => {
      return !router.resolve(item.url).name?.toString().includes('Error404')
    })
}
const emit = defineEmits<(event: 'close') => void>()
function close() {
  selectedOption.value = undefined
  emit('close')
}

const { shift, meta } = useMagicKeys()

function visitSelection(newValue: SearchResult) {
  if (!newValue) {
    close()
    return
  }

  addToRecent(newValue)

  if (meta.value) {
    open(newValue.url, '_blank')
  } else if (shift.value) {
    open(newValue.url)
  } else {
    router.push(newValue.url)
  }

  close()
}

onKeyStroke('Escape', () => {
  close()
})

const showGlobalSearchOptions = computed(() => {
  return results.value.length > 0 || recentSearches.value.length > 0 || query.value !== ''
})
</script>

<template>
  <DialogRoot v-model:open="isShowing">
    <DialogPortal>
      <div class="fixed inset-0 overflow-y-auto p-4 sm:p-6 md:p-20">
        <AnimatePresence>
          <Motion :initial="{ opacity: 0 }" :animate="{ opacity: 1 }" :exit="{ opacity: 0 }">
            <DialogOverlay
              class="data-[state=open]:animate-overlayShow fixed inset-0 bg-gray-900/80"
            />
          </Motion>

          <Motion :initial="{ opacity: 0 }" :animate="{ opacity: 1 }" :exit="{ opacity: 0 }">
            <DialogContent
              class="mx-auto max-w-xl transform overflow-hidden rounded-md bg-white shadow-2xl ring-1 ring-black ring-opacity-5 transition-all"
            >
              <VisuallyHidden>
                <DialogTitle>
                  {{ Translator.trans('u2.global_search') }}
                </DialogTitle>
                <DialogDescription>
                  {{ Translator.trans('u2.global_search.description') }}
                </DialogDescription>
              </VisuallyHidden>

              <ComboboxRoot
                :model-value="selectedOption"
                :ignore-filter="true"
                :open="showGlobalSearchOptions"
                @update:model-value="visitSelection"
              >
                <ComboboxAnchor class="relative border-b border-gray-100">
                  <AppLoader
                    v-if="loading"
                    size="small"
                    class="pointer-events-none absolute left-4 top-3.5 size-5 text-slate-400"
                  />
                  <SvgIcon
                    v-else
                    icon="search"
                    size="large"
                    class="pointer-events-none absolute left-4 top-3.5 text-slate-400"
                    aria-hidden="true"
                  />
                  <ComboboxInput
                    v-model="query"
                    class="h-12 w-full border-0 bg-transparent pl-12 pr-4 text-slate-800 placeholder-slate-400 focus:ring-0"
                    :placeholder="Translator.trans('u2.search')"
                  />
                </ComboboxAnchor>

                <ComboboxContent class="max-h-96 scroll-py-3 list-none overflow-y-auto py-3 pl-0">
                  <ComboboxViewport as-child>
                    <ComboboxGroup v-if="query === '' && recentSearches.length > 0">
                      <ComboboxLabel as="h2" class="pl-6 pr-4 text-sm font-semibold text-gray-500">
                        <span class="font-bold text-gray-700">
                          {{ Translator.trans('u2.search.recent_searches') }}
                        </span>
                      </ComboboxLabel>
                      <GlobalSearchItem
                        v-for="recentSearch in recentSearches.slice(0, 5)"
                        :key="recentSearch.name + recentSearch.url"
                        :item="recentSearch"
                      />
                    </ComboboxGroup>

                    <template v-if="results.length > 0">
                      <GlobalSearchItem
                        v-for="result in results"
                        :key="result.name + result.url"
                        :item="result"
                      />
                    </template>

                    <ComboboxEmpty
                      v-else-if="query !== '' && results.length === 0"
                      class="px-6 py-14 text-center sm:px-14"
                    >
                      <p class="mt-2 text-gray-500">
                        {{ Translator.trans('u2_table.no_results_that_match_search') }}
                      </p>
                    </ComboboxEmpty>
                  </ComboboxViewport>
                </ComboboxContent>
              </ComboboxRoot>
            </DialogContent>
          </Motion>
        </AnimatePresence>
      </div>
    </DialogPortal>
  </DialogRoot>
</template>
