import axios from 'axios'
import type { AxiosResponse } from 'axios'
import type { Datasheet, LayoutItem } from '@js/model/datasheet'
import type { HydraCollectionResponse } from '@js/types'
import type { SortingDirection } from '@js/utilities/getNextSortingDirection'

export const itemApiBasePath = '/api/items'

export type LayoutItemQuery = {
  page?: number
  itemsPerPage?: number
  pagination?: boolean
  'sort[name]'?: SortingDirection
  'sort[refId]'?: SortingDirection
  'sort[type]'?: SortingDirection
  'sort[editable]'?: SortingDirection
  'datasheet.id'?: Datasheet['id'] | Array<Datasheet['id']>
  search?: string | Array<string>
}

export function fetchDatasheetItemsByQuery(query?: LayoutItemQuery) {
  return axios.get<HydraCollectionResponse<LayoutItem>>(itemApiBasePath, {
    params: { ...query },
  })
}

export function deleteDatasheetItem(payload: Pick<LayoutItem, '@id'>) {
  if (!payload['@id']) throw new Error('Unable to delete record without @id')
  return axios.delete<LayoutItem>(payload['@id'])
}

export function createDatasheetItem(payload: Partial<LayoutItem> & Pick<LayoutItem, 'type'>) {
  return axios.post<Partial<LayoutItem>, AxiosResponse<LayoutItem>>(`${itemApiBasePath}`, payload)
}

export function updateDatasheetItem(payload: Partial<LayoutItem> & Pick<LayoutItem, '@id'>) {
  return axios.patch<Partial<LayoutItem> & Pick<LayoutItem, '@id'>, AxiosResponse<LayoutItem>>(
    payload['@id'],
    payload
  )
}

export function fetchDatasheetItemByIri(iri: LayoutItem['@id']) {
  return axios.get<LayoutItem>(iri)
}

export function fetchDatasheetItemById(id: LayoutItem['id']) {
  return axios.get<LayoutItem>(`${itemApiBasePath}/${id}`)
}

export function fetchAllDatasheetItems() {
  return axios.get<HydraCollectionResponse<LayoutItem>>(itemApiBasePath, {
    params: { pagination: false },
  })
}

export const itemApi = {
  fetchDatasheetItemsByQuery,
  deleteDatasheetItem,
  createDatasheetItem,
  updateDatasheetItem,
  fetchDatasheetItemByIri,
  fetchDatasheetItemById,
  fetchAllDatasheetItems,
  basePath: itemApiBasePath,
}
