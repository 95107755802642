import { syncRef, useLocalStorage, useSessionStorage } from '@vueuse/core'
import { acceptHMRUpdate, defineStore } from 'pinia'
import { transformIfTruthy } from '@js/utilities/data-transformers'
import type { Period } from '@js/api/periodApi'
import type { Datasheet, Field } from '@js/model/datasheet'
import type { DatasheetCollection } from '@js/model/datasheetCollection'
import type { Unit } from '@js/model/unit'
import type { UnitHierarchy } from '@js/model/unit_hierarchy'
import type { LocationQuery } from 'vue-router'

export type SelectedView = 'unit' | 'group'

export type DatasheetParameters = {
  unit?: Unit['id']
  period?: Period['id']
  layout?: Datasheet['id']
  field?: Field['id']
  layoutCollection?: DatasheetCollection['id']
  selectedView: SelectedView
  unitHierarchy?: UnitHierarchy['id']
}

export const useDatasheetParametersStore = defineStore('datasheet-parameters', () => {
  const parametersLocal = useLocalStorage<DatasheetParameters>('layout:params', {
    selectedView: 'unit',
    unit: undefined,
    unitHierarchy: undefined,
    period: undefined,
    layout: undefined,
    field: undefined,
    layoutCollection: undefined,
  })
  const parameters = useSessionStorage<DatasheetParameters>('layout:params', parametersLocal.value)

  syncRef(parameters, parametersLocal, { direction: 'ltr' })

  function updateFromLocationQuery(query: LocationQuery) {
    if ('period' in query) {
      parameters.value.period = transformIfTruthy(query.period, Number)
    }

    if ('field' in query) {
      parameters.value.field = transformIfTruthy(query.field, Number)
    }

    if ('unit' in query) {
      parameters.value.unit = transformIfTruthy(query.unit, Number)
      parameters.value.selectedView = 'unit'
    }

    if ('unitHierarchy' in query) {
      parameters.value.unitHierarchy = transformIfTruthy(query.unitHierarchy, Number)
      parameters.value.selectedView = 'group'
    }
  }

  return {
    parameters,
    updateFromLocationQuery,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useDatasheetParametersStore, import.meta.hot))
}
