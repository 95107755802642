import axios from 'axios'
import type { AxiosResponse } from 'axios'
import type { Condition } from '@js/api/transitionConditionApi'
import type { Action } from '@js/api/transitionActionApi'
import type { Status } from '@js/model/status'
import type { ApiResource, ApiResourceId } from '@js/types'

export type Transition = ApiResource & {
  id: number
  name: string
  conditions: Array<Condition>
  actions: Array<Action>
  description: string
  originStatus: Status['@id']
  destinationStatus: Status['@id']
  workflow: ApiResourceId
}

export type TransitionWithEmbeddedStatus = Omit<
  Transition,
  'originStatus' | 'destinationStatus'
> & {
  originStatus: Status
  destinationStatus: Status
}

export const workflowTransitionBasePath = '/api/transitions'

export function fetchWorkflowTransitionById(id: number) {
  return fetchWorkflowTransitionByIri(`${workflowTransitionBasePath}/${id}`)
}

export function fetchWorkflowTransitionByIri(iri: ApiResourceId) {
  return axios.get<TransitionWithEmbeddedStatus>(iri)
}

export function saveWorkflowTransition(transition: Partial<Transition>) {
  if (transition.id === undefined) {
    return axios.post<Transition, AxiosResponse<TransitionWithEmbeddedStatus>>(
      workflowTransitionBasePath,
      transition
    )
  }
  return axios.patch<Transition, AxiosResponse<TransitionWithEmbeddedStatus>>(
    `${workflowTransitionBasePath}/${transition.id}`,
    transition
  )
}

export function deleteWorkflowTransitionById(id: Transition['id']) {
  return axios.delete(`${workflowTransitionBasePath}/${id}`)
}
