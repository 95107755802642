import axios from 'axios'
import { useAuthStore } from '@js/stores/auth'
import type { HydraCollectionResponse } from '@js/types'
import type { SavedFilter, SavedFilterSubscription } from '@js/model/saved-filter'
import type { User } from '@js/model/user'
import type { UserGroup } from '@js/model/userGroup'
import type { SortingDirection } from '@js/utilities/getNextSortingDirection'

export const savedFilterApiBasePath = '/api/saved-filters'
export function fetchSavedFiltersByQuery(query?: {
  page?: number
  itemsPerPage?: number
  pagination?: boolean
  'sort[name]'?: SortingDirection
  'sort[tableViewConfigurationFullyQualifiedClass]'?: SortingDirection
  'sort[description]'?: SortingDirection
  'sort[owner.username]'?: SortingDirection
  'sort[createdBy.username]'?: SortingDirection
  'sort[updatedBy.username]'?: SortingDirection
  'sort[createdAt]'?: SortingDirection
  'sort[public]'?: SortingDirection
  search?: string | Array<string>
}) {
  return axios.get<HydraCollectionResponse<SavedFilter>>(savedFilterApiBasePath, {
    params: { ...query },
  })
}

export function fetchAllSavedFilters() {
  return fetchSavedFiltersByQuery({ pagination: false })
}

export function fetchSavedFilterMyFavourites() {
  return axios.get<HydraCollectionResponse<SavedFilter>>(
    useAuthStore().user?.['@id'] + '/favourite-saved-filters'
  )
}

export function addSavedFilterToMyFavourites(savedFilter: SavedFilter) {
  return axios.post<HydraCollectionResponse<SavedFilter>>(
    useAuthStore().user?.['@id'] + '/favourite-saved-filters',
    { savedFilter: savedFilter['@id'] }
  )
}
export function removeSavedFilterFromMyFavourites(savedFilter: Pick<SavedFilter, 'id'>) {
  return axios.delete<HydraCollectionResponse<SavedFilter>>(
    useAuthStore().user?.['@id'] + '/favourite-saved-filters/' + savedFilter.id
  )
}

export function fetchSavedFilterById(id: number) {
  return axios.get<SavedFilter>(`${savedFilterApiBasePath}/${id}`)
}

export function fetchSavedFilterAssignedUserGroups(id: number) {
  return axios.get<HydraCollectionResponse<UserGroup>>(`${savedFilterApiBasePath}/${id}/groups`, {
    params: { pagination: false },
  })
}

export function updateSavedFilterAssignedUserGroups(
  id: number,
  updatedGroups: Array<NonNullable<UserGroup['@id']>>
) {
  return axios.patch<HydraCollectionResponse<UserGroup>>(`${savedFilterApiBasePath}/${id}/groups`, {
    groups: updatedGroups,
  })
}

export function fetchSavedFilterAssignedUsers(id: number) {
  return axios.get<HydraCollectionResponse<User>>(`${savedFilterApiBasePath}/${id}/direct-users`, {
    params: { pagination: false },
  })
}

export function updateSavedFilterAssignedUsers(
  id: number,
  updatedUsers: Array<NonNullable<User['@id']>>
) {
  return axios.patch<HydraCollectionResponse<User>>(
    `${savedFilterApiBasePath}/${id}/direct-users`,
    {
      directUsers: updatedUsers,
    }
  )
}

export function fetchSavedFilterInheritedAssignedUsers(id: number) {
  return axios.get<HydraCollectionResponse<User>>(
    `${savedFilterApiBasePath}/${id}/inherited-users`,
    { params: { pagination: false } }
  )
}

export function fetchSavedFilterSubscriptions(id: SavedFilter['id']) {
  return axios.get<HydraCollectionResponse<SavedFilterSubscription>>(
    `${savedFilterApiBasePath}/${id}/saved-filter-subscriptions`,
    {
      params: { pagination: false },
    }
  )
}

export function saveSavedFilter(savedFilter: Partial<SavedFilter>) {
  if (savedFilter.id === undefined) {
    return axios.post<SavedFilter>(savedFilterApiBasePath, savedFilter)
  }
  return axios.patch<SavedFilter>(`${savedFilterApiBasePath}/${savedFilter.id}`, savedFilter)
}

export function deleteSavedFilterById(id: SavedFilter['id']) {
  return axios.delete(`${savedFilterApiBasePath}/${id}`)
}
