import axios from 'axios'
import type { AuditLog } from '@js/model/auditLog'
import type {
  ApiPaginationQueryParams,
  ApiResource,
  ApiResourceId,
  HydraCollectionResponse,
} from '@js/types'
import type { SortingDirection } from '@js/utilities/getNextSortingDirection'

export const periodApiBasePath = '/api/periods'

export type PeriodCollectionQuery = {
  page?: number
  itemsPerPage?: number
  pagination?: boolean
  'sort[closed]'?: SortingDirection
  'sort[endDate]'?: SortingDirection
  'sort[name]'?: SortingDirection
  'sort[previousPeriod.name]'?: SortingDirection
  'sort[startDate]'?: SortingDirection
  'sort[description]'?: SortingDirection
  search?: string | Array<string>
}

export type Period = ApiResource & {
  closed: boolean
  endDate: string
  id: number
  name: string
  previousPeriod: ApiResourceId | null
  startDate: string
  description: string
  createdAt: string
  createdBy: ApiResourceId
  updatedAt: string
  updatedBy: ApiResourceId
}

export function fetchPeriodsByQuery(query?: PeriodCollectionQuery) {
  return axios.get<HydraCollectionResponse<Period>>(periodApiBasePath, {
    params: { ...query },
  })
}
export function fetchAllPeriods() {
  return fetchPeriodsByQuery({ pagination: false })
}

export function fetchPeriodById(id: number) {
  return axios.get<Period>(`${periodApiBasePath}/${id}`)
}

export function fetchPeriodByIri(iri: string) {
  return axios.get<Period>(iri)
}

export function deletePeriod(id: number) {
  return axios.delete<Period>(`${periodApiBasePath}/${id}`)
}

export function createPeriod(period: Partial<Period>) {
  return axios.post<Period>(periodApiBasePath, period)
}

export function updatePeriod(period: Partial<Period>) {
  return axios.patch<Period>(`${periodApiBasePath}/${period.id}`, period)
}

export function fetchPeriodLogsByQuery(id: Period['id'], query?: ApiPaginationQueryParams) {
  return axios.get<HydraCollectionResponse<AuditLog>>(`${periodApiBasePath}/${id}/logs`, {
    params: { ...query },
  })
}
