import axios from 'axios'
import type { ApiResource, HydraCollectionResponse } from '@js/types'

export const transitionActionTypeApiBasePath = '/api/workflow/transition/action-types'

export type ActionType = ApiResource & {
  id: string
  description: string
  help: string
  name: string
}

export function fetchAllTaskTrasitionActionTypes() {
  return axios.get<HydraCollectionResponse<ActionType>>(transitionActionTypeApiBasePath, {
    params: { pagination: false },
  })
}
