import axios from 'axios'
import Routing from '@js/Routing'
import type { Period } from '@js/api/periodApi'
import type { DatasheetCollection } from '@js/model/datasheetCollection'
import type { Datasheet, GroupViewData } from '@js/model/datasheet'
import type { UnitHierarchy } from '@js/model/unit_hierarchy'

type GroupViewDataQuery = {
  period: Period['id']
  unitHierarchy: UnitHierarchy['id']
  layout: Datasheet['id']
  layoutCollection: DatasheetCollection['id']
}

export function fetchUnitHierarchyViewDataByQuery(query: GroupViewDataQuery) {
  return axios.get<GroupViewData>(Routing.generate('u2_layout_groupviewdata'), {
    params: {
      ...query,
    },
  })
}

export function downloadUnitHierarchyViewDataXls(params: {
  datasheetCollection: DatasheetCollection['id']
  datasheet: Datasheet['id']
  period: Period['id']
  unitHierarchy: UnitHierarchy['id']
}) {
  return axios.get<Blob>(Routing.generate('u2_datasheets_unithierarchyview_export', params), {
    responseType: 'blob',
  })
}

export const groupViewDataApi = {
  downloadUnitHierarchyViewDataXls,
  fetchUnitHierarchyViewDataByQuery,
}
