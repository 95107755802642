import axios from 'axios'
import Routing from '@js/Routing'
import type { AuditLog } from '@js/model/auditLog'
import type { ApiPaginationQueryParams, ApiResourceId, HydraCollectionResponse } from '@js/types'
import type { RoleResource } from '@js/model/role'
import type { Unit } from '@js/model/unit'
import type { User, UserSettings, UserWithAllProperties } from '@js/model/user'
import type { UserGroup } from '@js/model/userGroup'
import type { Dashboard } from '@js/model/dashboard'

export const userApiBasePath = '/api/users'
type UserCollectionQuery = ApiPaginationQueryParams & {
  'sort[id]'?: string
  'sort[username]'?: string
  'sort[locked]'?: string
  'sort[passwordExpires]'?: string
  'sort[lastLogin]'?: string
  'sort[lastActivity]'?: string
  'sort[contact.nameLast]'?: string
  'sort[contact.nameFirst]'?: string
  'sort[contact.email]'?: string
  'sort[contact.company]'?: string
  'sort[contact.country.nameShort]'?: string
  'sort[contact.telephone]'?: string
  'sort[contact.mobile]'?: string
  'sort[contact.fax]'?: string
  'sort[parentUser.username]'?: string
  'sort[accountExpires]'?: string
  search?: string | Array<string>
}

export function fetchAllUsers() {
  return axios.get<HydraCollectionResponse<User>>(userApiBasePath, {
    params: { pagination: false, sort: { username: 'ASC' } },
  })
}

export function fetchUserById(id: User['id']) {
  return axios.get<User>(`${userApiBasePath}/${id}`)
}

export function fetchUserByIri(iri: ApiResourceId) {
  return axios.get<User>(iri)
}

export function fetchUserAssignedDashboards(id: User['id']) {
  return axios.get<HydraCollectionResponse<Dashboard>>(`${userApiBasePath}/${id}` + `/dashboards`)
}

export function deleteUserById(id: User['id']) {
  return axios.delete(`${userApiBasePath}/${id}`)
}

export function fetchUserAssignedRoles(id: User['id']) {
  return axios.get<HydraCollectionResponse<RoleResource>>(`${userApiBasePath}/${id}/roles`, {
    params: { pagination: false },
  })
}

export function fetchUserAssignedUnits(id: User['id']) {
  return axios.get<HydraCollectionResponse<Unit>>(`${userApiBasePath}/${id}/units`, {
    params: { pagination: false },
  })
}

export function fetchUserAssignedUserGroups(id: User['id']) {
  return axios.get<HydraCollectionResponse<UserGroup>>(`${userApiBasePath}/${id}/groups`, {
    params: { pagination: false },
  })
}

export function fetchUserInheritedRoles(id: User['id']) {
  return axios.get<HydraCollectionResponse<RoleResource>>(
    `${userApiBasePath}/${id}/inherited-roles`,
    {
      params: { pagination: false },
    }
  )
}

export function updateUserAssignedRoles(
  id: User['id'],
  updatedRoles: Array<NonNullable<RoleResource['@id']>>
) {
  return axios.patch<HydraCollectionResponse<RoleResource>>(`${userApiBasePath}/${id}/roles`, {
    roles: updatedRoles,
  })
}

export function updateUserAssignedUnits(
  id: User['id'],
  updatedUnits: Array<NonNullable<Unit['@id']>>
) {
  return axios.patch<HydraCollectionResponse<Unit>>(`${userApiBasePath}/${id}/units`, {
    units: updatedUnits,
  })
}

export function fetchUserInheritedUnits(id: User['id']) {
  return axios.get<HydraCollectionResponse<Unit>>(`${userApiBasePath}/${id}/inherited-units`, {
    params: { pagination: false },
  })
}

export function fetchUserSettings(id: User['id']) {
  return axios.get<UserSettings>(`${userApiBasePath}/${id}/settings`)
}

export function updateUserSettings(settings: UserSettings) {
  return axios.patch<UserSettings>(settings['@id'], settings)
}

export function updateUserAssignedUserGroups(
  id: User['id'],
  updatedUserGroups: Array<NonNullable<UserGroup['@id']>>
) {
  return axios.patch<HydraCollectionResponse<UserGroup>>(`${userApiBasePath}/${id}/groups`, {
    groups: updatedUserGroups,
  })
}

export function resetUserTrustedDevices(userIri: User['@id']) {
  return axios.patch(`${userIri}/reset-trusted-devices`, {})
}

export function unlockUser(id: User['id']) {
  return axios.post(Routing.generate('u2_user_unlock', { id }))
}

export function fetchUserAuthorisations(userId: User['id']) {
  return axios.get(Routing.generate('u2_user_userrightsjson', { id: userId }))
}

export function updateUser(
  user: Partial<
    Omit<UserWithAllProperties, 'contact'> & {
      contact: Partial<UserWithAllProperties['contact']>
    }
  >
) {
  return axios.patch<UserWithAllProperties>(`${userApiBasePath}/${user.id}`, user)
}

export function createUser(
  user: Partial<
    Omit<UserWithAllProperties, 'contact'> & {
      contact: Partial<UserWithAllProperties['contact']>
    }
  >
) {
  return axios.post<UserWithAllProperties>(userApiBasePath, user)
}

export function fetchUsersByQuery(query: UserCollectionQuery) {
  return axios.get<HydraCollectionResponse<UserWithAllProperties>>(userApiBasePath, {
    params: { ...query },
  })
}

export function fetchUserLogsByQuery(id: User['id'], query?: ApiPaginationQueryParams) {
  return axios.get<HydraCollectionResponse<AuditLog>>(`${userApiBasePath}/${id}/logs`, {
    params: { ...query },
  })
}
