import { createQueryKeys } from '@lukemorales/query-key-factory'
import { toValue } from 'vue'
import invariant from 'tiny-invariant'
import * as PeriodApi from '@js/api/periodApi'
import type { Period } from '@js/api/periodApi'
import type { MaybeRefOrGetter } from 'vue'
import type { MaybeRef } from '@vueuse/core'
import type { UnitCollectionQuery } from '@js/api/unitApi'

export const periods = createQueryKeys('periods', {
  all: {
    queryKey: null,
    queryFn: () => PeriodApi.fetchAllPeriods().then((response) => response.data),
  },
  list: (filters?: MaybeRefOrGetter<UnitCollectionQuery>) => ({
    queryKey: [filters],
    queryFn: () =>
      PeriodApi.fetchPeriodsByQuery({ ...toValue(filters) }).then((response) => response.data),
  }),
  single: (id: MaybeRef<Period['id'] | undefined>) => {
    return {
      queryKey: [id],
      queryFn: () => {
        const value = toValue(id)
        invariant(value !== undefined, 'id is required')
        return PeriodApi.fetchPeriodById(value).then((response) => response.data)
      },
    }
  },
})
