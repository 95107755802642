import axios from 'axios'
import type { SortingDirection } from '@js/utilities/getNextSortingDirection'
import type { ApiPaginationQueryParams, HydraCollectionResponse } from '@js/types'
import type { Status } from '@js/model/status'

export const statusApiBasePath = '/api/statuses'
type StatusCollectionQuery = ApiPaginationQueryParams & {
  'sort[name]'?: SortingDirection
  'sort[type]'?: SortingDirection
  search?: string | Array<string>
}

export function fetchAllStatuses() {
  return axios.get<HydraCollectionResponse<Status>>(statusApiBasePath, {
    params: { pagination: false },
  })
}

export function fetchStatusesByQuery(query: StatusCollectionQuery) {
  return axios.get<HydraCollectionResponse<Status>>(statusApiBasePath, {
    params: { ...query },
  })
}

export function fetchStatusById(id: number) {
  return axios.get<Status>(`${statusApiBasePath}/${id}`)
}

export function createStatus(status: Partial<Status>) {
  return axios.post<Status>(statusApiBasePath, status)
}

export function updateStatus(status: Partial<Status>) {
  return axios.patch<Status>(`${statusApiBasePath}/${status.id}`, status)
}

export function deleteStatus(status: Status) {
  return axios.delete(`${status['@id']}`)
}

export function deleteStatusById(id: Status['id']) {
  return axios.delete(`${statusApiBasePath}/${id}`)
}
export function fetchStatusByIri(iri: NonNullable<Status['@id']>) {
  return axios.get<Status>(iri)
}
