import axios from 'axios'
import type { BaseDocumentSection } from '@js/model/document'
import type { DocumentTemplate, documentTemplateTypes } from '@js/model/document-template'
import type { GroupPermission, UserPermission } from '@js/model/permission'
import type { ApiResourceId, HydraCollectionResponse } from '@js/types'
import type { SortingDirection } from '@js/utilities/getNextSortingDirection'

export const documentTemplateApiBasePath = '/api/document-templates'

export type DocumentTemplateSectionContent = {
  '@type': 'DocumentTemplateSectionContent'
  '@id': string
  content: string
}

export type DocumentTemplateSection = BaseDocumentSection & {
  '@type': 'DocumentTemplateSection'
}

export function fetchDocumentTemplatesByQuery(query: {
  page?: number
  pagination?: boolean
  itemsPerPage?: number
  'sort[name]'?: SortingDirection
  'sort[type]'?: SortingDirection
  'sort[createdAt]'?: SortingDirection
  'sort[updatedAt]'?: SortingDirection
  'sort[createdBy.username]'?: SortingDirection
  'sort[updatedBy.username]'?: SortingDirection
}) {
  return axios.get<HydraCollectionResponse<DocumentTemplate>>(documentTemplateApiBasePath, {
    params: { ...query },
  })
}

export function fetchDocumentTemplateById(id: number) {
  return fetchDocumentTemplateByIri(`${documentTemplateApiBasePath}/${id}`)
}

export function fetchDocumentTemplateByIri(iri: ApiResourceId) {
  return axios.get<DocumentTemplate>(iri)
}

export function deleteDocumentTemplate(id: number) {
  return axios.delete<DocumentTemplate>(`${documentTemplateApiBasePath}/${id}`)
}

export function updateDocumentTemplate(documentTemplate: Partial<DocumentTemplate>) {
  return axios.patch<DocumentTemplate>(
    `${documentTemplateApiBasePath}/${documentTemplate.id}`,
    documentTemplate
  )
}

export function fetchDocumentTemplateSections(id: DocumentTemplate['id']) {
  return axios.get<HydraCollectionResponse<DocumentTemplateSection>>(
    `${documentTemplateApiBasePath}/${id}/sections`
  )
}

export function fetchDocumentTemplateRenderedSections(id: DocumentTemplate['id']) {
  return axios.get<HydraCollectionResponse<DocumentTemplateSectionContent>>(
    `${documentTemplateApiBasePath}/${id}/rendered-sections`
  )
}

export function createDocumentTemplate(
  file: File,
  type: keyof typeof documentTemplateTypes,
  name: string,
  description: string | null
) {
  const requestData = new FormData()

  requestData.append('type', type)
  requestData.append('name', name)
  if (description) {
    requestData.append('description', description)
  }
  requestData.append('uploadedFile', file, file.name)

  return axios.post<DocumentTemplate>(documentTemplateApiBasePath, requestData)
}

export function fetchDocumentTemplateUserPermissions(documentTemplate: DocumentTemplate) {
  return axios.get<HydraCollectionResponse<UserPermission>>(
    `${documentTemplate['@id']}/user-permissions`
  )
}

export function fetchDocumentTemplateGroupPermissions(documentTemplate: DocumentTemplate) {
  return axios.get<HydraCollectionResponse<GroupPermission>>(
    `${documentTemplate['@id']}/group-permissions`
  )
}

export function updateDocumentTemplateUserPermissions(
  documentTemplate: DocumentTemplate,
  userPermissions: Array<Omit<UserPermission, '@id' | 'id'>>
) {
  return axios.patch<HydraCollectionResponse<UserPermission>>(
    `${documentTemplate['@id']}/user-permissions`,
    { userPermissions }
  )
}

export function updateDocumentTemplateGroupPermissions(
  documentTemplate: DocumentTemplate,
  groupPermissions: Array<Omit<GroupPermission, '@id' | 'id'>>
) {
  return axios.patch<HydraCollectionResponse<GroupPermission>>(
    `${documentTemplate['@id']}/group-permissions`,
    { groupPermissions }
  )
}
